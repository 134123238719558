import React from "react";
import bench from "../static/waxcarversabout/bench.png";
import jussi from "../static/waxcarversabout/jussi.png";
import ananas from "../static/waxcarversabout/ananas.png";
import waxcarvers from "../static/waxcarversabout/waxcarvers.png";
import benchwebp from "../static/waxcarversabout/bench.webp";
import jussiwebp from "../static/waxcarversabout/jussi.webp";
import ananaswebp from "../static/waxcarversabout/ananas.webp";
import waxcarverswebp from "../static/waxcarversabout/waxcarvers.webp";
import { JoinNewsLetterButton } from "../components/Common/NewsLetterPlug";
import Layout from "../components/layout";
import { PageTitle } from "../components/Common/PageTitle";
import { Photo } from "../components/Photo";

const About = () => (
  <Layout
    title="Wax Carvers - About Wax Carvers"
    description="Hi I'm Sandy! I decided to teach wax carving to make wax carving available to you so you can go ahead and fall in love with it yourself. You will learn through video tutorials, written handouts and have the ability to ask questions."
  >
    <div className="relative pt-16 pb-6 px-4 sm:px-6 lg:pt-24 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3"></div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <PageTitle title="Hi I’m Wax Carvers!" subtitle="" />
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <Photo
              collage={bench}
              collagewp={benchwebp}
              altText="Sandy behind a jewellers bench piercing a piece of ringtube"
              small
            />
            <p>
              Wax Carvers is 90% me, <a href="/sandy">Sandy</a>&nbsp;and 10% my
              husband Jussi. He does the complicated coding part of the website,
              and is the sole proofreader of everything on the website and all
              emails. We’re both not native English speakers so the occasional
              mistake happens!
            </p>
            <Photo
              collage={jussi}
              collagewp={jussiwebp}
              caption="Jussi, complicated coding on the balcony"
              altText="Jussi sitting on the balcony with a laptop in his lap"
              small
            />
            <p>
              Since you’re here to learn wax carving and not complicated coding,
              I am Wax Carvers.
            </p>
            <Photo
              collage={waxcarvers}
              collagewp={waxcarverswebp}
              altText="Close up of Sandy holding a Wax carvers sticker in front of a purple bouquete of flowers"
              small
            />
            <p>
              You want to learn wax carving and I can teach you. Wherever you
              are in the world! My home studio might be in a city in the
              Netherlands that you’ve probably never heard of, the classes are
              all online! You get access to pre recorded videos that you can
              watch as often as you like. In the comfort of your own studio. In
              a city that I possibly have never heard of!
            </p>
            <p>
              There’s a whooole bunch of <a href="/classes"> classes</a> already
              available. Do you want to learn to carve rings? Bezels for
              gemstones? A combination? Charms? Hard wax or soft wax? Browse
              through the available classes and find what you want to learn! New
              classes get added irregularly, when I finish them.
            </p>
          </div>

          <h2 className="mb-6 mt-12 block text-3xl text-center leading-6 tracking-tight text-blue-700 sm:text-4xl">
            Teaching philosophy
          </h2>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>
              Every teacher has their own style. These are my reasons for
              teaching the way that I teach!{" "}
            </p>
          </div>

          <h3 className="my-8 block text-xl text-center leading-3 tracking-tight text-blue-700">
            Use the tools you have
          </h3>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>
              One of the main reasons I love wax carving is that you can do it
              with only a few tools. Yes, there are plenty of specialized tools
              out there to make carving easier and quicker. But they’re not
              ESSENTIAL to enjoy wax carving.
            </p>
            <p>
              I don’t want to start a class by telling you to first invest in
              all these specialized tools. So I don’t! I use{" "}
              <a href="/blog/post/what-tools-do-I-need-for-wax-carving">
                basic tools
              </a>{" "}
              in the classes that most people already have in their studio. If
              you don’t have any tools at all you can easily get these without
              spending a fortune. And if you do have specialized tools you can
              use them!
            </p>
            <p>
              When you get into specialized tools it becomes so personal what
              you want and need. It’s much easier to start with what you already
              have. You can read more about this point in the blog post{" "}
              <a href="/blog/post/just-start">just start!</a>
            </p>
          </div>

          <h3 className="my-8 block text-xl text-center leading-3 tracking-tight text-blue-700">
            Learn that difficult technique
          </h3>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>
              I teach a lot of traditional jewellery making techniques. That
              might sound boring and difficult, and you know what? Sometimes it
              is! Learning to file a perfect 90-degree corner is frustrating.
              But so was learning to pierce a straight line!{" "}
            </p>
            <p>Don’t limit yourself!</p>
            <p>
              Learn that difficult technique. Practice and practice and practice
              until you can do it. When you master a new technique a whole new
              world of design possibilities opens up for you!{" "}
            </p>{" "}
            <p>
              And just because you’re learning traditional techniques, doesn’t
              mean you have to use them to make traditional pieces. I always try
              to add some fun to the pieces I make for the classes. Have you
              seen my pineapple settings!
            </p>
            <Photo
              collage={ananas}
              collagewp={ananaswebp}
              caption="Look how cute!"
              altText="2 ananas carvings, a small one with a red center stone and a bigger one with an oval yellow stone"
              small
            />
          </div>

          <h3 className="my-8 block text-xl text-center leading-3 tracking-tight text-blue-700">
            It’s not only about the result
          </h3>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>
              The piece you learn to make is a vessel to teach you all the
              different techniques. Learn different ways to make the same piece!
              Learn variations and different interpretations. By trying
              different techniques you find the ones that you like. The ones you
              NEVER want to do again. The ones you want to practice more.{" "}
            </p>
            <p>
              And the ones you can use for different pieces! I teach you how to
              taper a ring and you realize you can taper this pendant you’ve
              been working on? That’s the best outcome for any class!
            </p>
          </div>
        </div>
      </div>
    </div>

    <div className="mt-12">
      <JoinNewsLetterButton />
    </div>
  </Layout>
);

export default About;
